import {
  TracingInstrumentation
} from "@grafana/faro-web-tracing";
import {
  ConsoleInstrumentation,
  ErrorsInstrumentation,
  FetchTransport,
  LogLevel,
  WebVitalsInstrumentation,
  initializeFaro as coreInit,
  getWebInstrumentations,
} from "@grafana/faro-web-sdk";

const otelDebug: boolean = false;

const appName: string = "lp-frontend-astro";

// Define patterns to ignore
const ignoredURLPatterns = [
  // Match with and without host to catch all variations
  /.*\/api\/telemetry/,
  /.*\/api\/telemetry$/,
  // Match New Relic
  /bam\.nr-data\.net/
];

(() => {
  const { FRONTEND_ENV } = window._env_;
  if (FRONTEND_ENV !== "prod" && !otelDebug) return;
  const faro = coreInit({
    app: {
      name: appName,
      environment: FRONTEND_ENV === "prod" ? "prod" : "nonprod",
    },
    instrumentations: [
      ...getWebInstrumentations(),
      new WebVitalsInstrumentation(),
      new ErrorsInstrumentation(),
      new ConsoleInstrumentation({
        disabledLevels: [
          LogLevel.TRACE,
          LogLevel.DEBUG,
          LogLevel.INFO,
          LogLevel.LOG,
        ],
      }),
      new TracingInstrumentation(),
    ],
    transports: [
      new FetchTransport({
        url: "/api/telemetry",
      }),
    ],
    ignoreUrls: ignoredURLPatterns
  });

  try {
    faro?.api.pushLog(["Faro was initialized"]);
  } catch (error) {
    console.log("Error initializing Faro", error);
  }
})();
